<template>
	<div>
		<BreadCrumbs :label="product.display_name" :pevents="events" />

		<v-col cols="12" sm="12" md="12" lg="11" xl="10" class="mx-auto pt-0">
			<v-row class="mx-2" no-gutters>
				<v-col class="cell rounded-lg elevation-2 overflow-hidden">
					<ProductField :product="product" layout="single"/>
				</v-col>

				<v-col class="pa-0 ml-sm-4" :style="$vuetify.breakpoint.width >= 600 ? {maxWidth: '270px'}:{}" style="min-width: 270px">
					<div class="rounded-lg overflow-hidden cell elevation-2 pt-6">
						<v-row align="center" no-gutters justify="center" class="sub-title-2 mb-2">{{ $tc('global.product.quantity', 1) }}</v-row>
						<v-row align="center" no-gutters justify="center" class="mb-4 flex-nowrap">
							<v-col cols="auto" class="cell3" style="border-radius: 50% 0 0 50%">
								<v-btn fab style="min-width: 20px; height: 24px; width: 24px;" class="elevation-2" color="primary" @click="product.count > 1 ? product.count-- : ''">
									<v-icon size="16">mdi-minus</v-icon>
								</v-btn>
							</v-col>
							<v-col cols="auto" class="text-center cell3 px-2" style="min-width: 50px">
								<div class="sub-title-1"><span class="paragraph">x </span>{{ product.count }}</div>
							</v-col>
							<v-col cols="auto" class="cell3" style="border-radius: 0 50% 50% 0">
								<v-btn fab style="min-width: 20px; height: 24px; width: 24px;" class="elevation-2" color="primary" @click="product.count < 10 ? product.count++ : ''">
									<v-icon size="16">mdi-plus</v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<v-divider class="my-5 mx-6" />
						<v-row class="mx-0" no-gutters>
							<v-col class="text-center">
								<div class="sub-title-2 mb-1">{{ $tc('global.product.pre_tax_price', 3) }}</div>
								<div class="headline-1 primary--text font-weight-bold" style="font-weight: 600" v-html="$options.filters.formatPrice((product.price + extensionsTotalPrice) * product.count, '€')"></div>
							</v-col>
						</v-row>
						<v-row class="mx-0 mt-5" no-gutters>
							<v-col cols="12">
								<Button :block="true" :loading="loading" :tile="true" :flat="true" color="primary" :label="$t('global.action.add_cart')" iconVal="mdi-cart" :iconSmall="true" :iconPosition="'right'" :noWrap="true" :click="() => addToCart()" large />
							</v-col>
							<v-col cols="12">
								<Button :block="true" :tile="true" :flat="true" :label="$tc('global.name.market', 2)" iconVal="mdi-shopping" :iconSmall="true" :iconPosition="'right'" :noWrap="true" :click="() => $router.push('/marketplace')" large color="cell2" />
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-row>
		</v-col>
	</div>
</template>

<script>
	export default {
		name: "ProductSingle",
		components: {
			Button: () => import('@/components/ui/Button.vue'),
			BreadCrumbs: () => import('@/components/ui/BreadCrumbs.vue'),
			ProductField: () => import('@/components/Product.vue')
		},
		data(){
			return {
				product: {},
				events: null,
				loading: false
			}
		},
		computed: {
			extensionsTotalPrice(){
				if(this.product.extensions){
					let res = 0

					for(let i = 0; i < this.product.extensions.length; i++){
						res += this.product.extensions[i].price * (this.product.extensions[i].count ? this.product.extensions[i].count : 0)
					}

					return res
				}
				else {
					return 0
				}
			}
		},
		methods: {
			getProduct(){
				this.$wsc.getItem('product', this.$route.params.id, success => {
					this.product = success
					this.events.notify('category', success.category)
					this.$set(this.product, 'count', 1)

					this.$wsc.getList('product/' + this.$route.params.id + '/extension', {}, success => {
						for (let i in success){
							this.$set(success[i], 'count' , 0)
						}

						this.$set(this.product, 'extensions', success)
					}, fail => {

					})
				}, fail => {

				})
			},
			addToCart(){
				this.loading = true
				let pcount = 1

				if (!isNaN(this.product.count))
					pcount = this.product.count

				this.$wsc.createItem('cart', { count: pcount, product: this.product.id }, success => {
					let extension_selected = 0
					if (this.product.extensions && this.product.extensions.length > 0){

						this.product.extensions.forEach(e => {
							if(e.count > 0){
								extension_selected++
							}
						})
						if(extension_selected > 0){
							this.$wsc.getList('cart/' + success.id + '/extension', {}, extensions => {
								success.extensions = []

								this.product.extensions.forEach(ext => {
									if(ext.count > 0){
										extensions.forEach(e => {
											if(e.extension.id === ext.id){
												this.$wsc.patchItem('cart/' + success.id + '/extension', e.id, { extension: e.extension.id, count: ext.count }, r => {
													success.extensions.push(r)
													this.$store.dispatch('cart/addItem', success)

													extension_selected--

													if(extension_selected === 0){
														this.loading = false
													}
												}, fail => {

												})
											}
											else {
												success.extensions.push(e)
											}
										})
									}
								})
							}, fail => {

							})
						}
					}
					else {
						this.$store.dispatch('cart/addItem', success)
						this.loading = false
					}

					this.$store.dispatch('snackbar/success', this.$t('snackbar.Product.addItem.success'))
				}, fail => {
					this.loading = false
					this.$store.dispatch('snackbar/success', this.$t('snackbar.Product.addItem.fail'))
				})
			},
			setUpHooks(){
				this.events = new this.$NVEvent("Product")
			}
		},
		created(){
			this.setUpHooks()
			this.getProduct()
		}

	}
</script>

<style scoped>

</style>